<script lang="ts" setup>
import type { StakeAccount, ValidatorData } from '~/store'
import { evaPerson } from '@quasar/extras/eva-icons'
import { formatAmount, lamportsToSol, shortenAddress } from '~/utils'

const props = defineProps({
  loading: Boolean,
  stakeAccount: {
    type: Object as () => StakeAccount,
    required: true,
  },
  validator: {
    type: Object as () => ValidatorData,
  },
})

const emit = defineEmits(['depositJpool'])

const shortAddress = computed(() => shortenAddress(props.validator?.voteId ?? '', 7))

const amount = computed(() => {
  const num = Number(lamportsToSol(props.stakeAccount?.account?.lamports ?? 0))
  return num >= 0 ? formatAmount(num, num < 1 ? 5 : 3) : ''
})

function depositJpool() {
  emit('depositJpool', props.stakeAccount)
}
</script>

<template>
  <div v-if="validator && validator.inJpool" class="redelegation__stake row items-center">
    <div class="redelegation__stake__validator">
      <div class="row justify-center q-mr-md">
        <q-avatar class="shadow-1" size="50px">
          <q-img :src="validator.iconUrl || validator.image" :error-src="validator.image" spinner-size="30px" spinner-color="light-gray">
            <template v-if="!validator.iconUrl && !validator.image" #default>
              <q-icon :name="evaPerson" />
            </template>
            <template #error>
              <q-icon :name="evaPerson" />
            </template>
          </q-img>
        </q-avatar>
      </div>
      <div class="column">
        <div class="redelegation__stake__validator__name q-pt-xs">
          {{ validator.name ? validator.name : shortAddress }}
          <q-tooltip anchor="bottom middle">
            <div class="text-body1 break-words">
              {{ $t('validators.table.tooltip.name') }}: {{ validator.name ? validator.name : validator.voteId }}
            </div>
          </q-tooltip>
        </div>

        <div class="redelegation__stake__validator__address">
          <span>
            {{ shortAddress }}
            <q-tooltip anchor="bottom middle" class="text-body2 break-words">
              {{ validator.voteId }}
            </q-tooltip>
          </span>
        </div>
      </div>
    </div>

    <div class="redelegation__stake__amount">
      {{ amount }} SOL
    </div>

    <q-btn class="redelegation__stake__btn" unelevated color="secondary" text-color="black" @click="depositJpool">
      {{ $t('smartStaking.redelegation.btn') }}
    </q-btn>

    <q-inner-loading :showing="loading">
      <q-spinner-gears size="48px" color="warning" />
    </q-inner-loading>
  </div>
</template>
