<script lang="ts" setup>
import dolphinIcon from '~/assets/img/icon/dolphin.svg'
import moneyIcon from '~/assets/img/icon/money-grouwth.svg'
import solIcon from '~/assets/img/icon/sol-bordered.svg'

type FaqSpec = {
  title: string
  texts: { text: string, isBold?: boolean }[]
  icon: string
  link: string
}

const { t } = useTranslation()

const data: FaqSpec[] = [
  {
    title: t('faq.special.sol.title'),
    texts: [
      { text: t('faq.special.sol.text.1') },
      { text: 'SOL', isBold: true },
      { text: t('faq.special.sol.text.2') },
    ],
    icon: dolphinIcon,
    link: 'https://www.orca.so',
  },
  {
    title: t('faq.special.cryptocurrency.title'),
    texts: [
      {
        text: t('faq.special.cryptocurrency.text.1'),
      },
    ],
    icon: moneyIcon,
    link: 'https://www.binance.com',
  },
  {
    title: t('faq.special.wallet.title'),
    texts: [
      {
        text: t('faq.special.wallet.text.1'),
      },
      { text: t('faq.special.wallet.text.2'), isBold: true },
    ],
    icon: solIcon,
    link: 'https://phantom.app/',
  },
]
</script>

<template>
  <section class="faq-special">
    <div class="container">
      <div class="page-container">
        <div class="faq-special__items">
          <faq-special-item
            v-for="item in data"
            :key="item.title"
            :title="item.title"
            :texts="item.texts"
            :link="item.link"
          >
            <template #icon>
              <img :src="item.icon" alt="faq icon">
            </template>
          </faq-special-item>
        </div>
      </div>
    </div>
  </section>
</template>
