<template>
  <q-page>
    <liquid-staking />
    <faq-section />
    <faq-special class="q-mt-lg" />
    <!-- <team-section /> -->
    <!-- <investor-section /> -->
    <!-- <hiring-section /> -->
  </q-page>
</template>
