<template>
  <section class="faq-section">
    <div class="container">
      <!-- <div class="faq-section__title">
        <section-title> FAQ </section-title>
        <a href="https://docs.jpool.one/" target="blank" title="jpool docs">More FAQ in the JPool documentation</a>
      </div> -->
      <div class="page-container">
        <!-- <div class="faq-section__cede-block">
          <cede-link class="q-mt-md q-mb-xl q-mx-auto"/>
        </div> -->
        <how-to-stake-btn />
        <div class="faq-list">
          <faq-item :title="$t('faq.staking.title')">
            <p>
              {{ $t('faq.staking.descr') }}
            </p>
            <p v-html="$t('faq.staking.readMore')" />
          </faq-item>
          <faq-item :title="$t('faq.stake.title')">
            <p>
              {{ $t('faq.stake.descr') }}
            </p>
          </faq-item>
          <faq-item :title="$t('faq.rewards.title')">
            <p>
              {{ $t('faq.rewards.descr') }}
            </p>
            <p v-html="$t('faq.rewards.readMore')" />
          </faq-item>
          <faq-item :title="$t('faq.delegator.title')">
            <p v-html="$t('faq.delegator.descr')" />
          </faq-item>
        </div>
      </div>
    </div>
  </section>
</template>
