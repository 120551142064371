<template>
  <section class="liquid-staking-section">
    <div class="container">
      <div class="liquid-staking__body">
        <page-descritpion class="q-mb-lg text-center home-description gt-sm">
          <div class="" v-html="$t('home.pageDesc')" />
        </page-descritpion>

        <div class="row justify-between items-start relative-position q-pt-sm">
          <div v-if="$q.screen.gt.sm" class="bg-banner justify-center items-center col-12 col-md-3">
            <div class="q-my-lg">
              <epoch />
              <jsol-price-card />
            </div>
          </div>

          <div class="stake-box-wrapper col-12 col-md-6">
            <stake-box-pool-standard />
          </div>

          <div v-if="$q.screen.gt.sm" class="bg-banner justify-start items-center no-wrap col-12 col-md-3 column relative" :class="{ 'text-primary': !isDark }">
            <holders-club-banner />
            <hc-booster-banner />
          </div>
        </div>

        <stake-accounts-redelegate class="q-my-lg" />

        <div v-if="$q.screen.lt.md" class="row justify-between items-start relative-position q-mt-lg q-pt-sm">
          <div class="bg-banner justify-center items-center col-12 col-md-3">
            <div class="q-my-lg">
              <epoch />
              <jsol-price-card />
            </div>
          </div>

          <div class="bg-banner justify-start items-center col-12 col-md-3 column" :class="{ 'text-primary': !isDark }">
            <holders-club-banner />
            <hc-booster-banner />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
