<script lang="ts" setup>
defineProps({
  title: {
    type: String,
    required: true,
  },
})

const expanded = ref(false)
</script>

<template>
  <q-expansion-item
    v-model="expanded"
    :label="title"
    class="faq-item"
  >
    <div class="faq-item__body">
      <slot />
    </div>
  </q-expansion-item>
</template>
